import { graphql, navigate, PageProps } from "gatsby";
import React, { useEffect } from "react";

interface DataProps {
  allCaseStudy: {
    nodes: {
      slug: string;
    }[];
  };
}

const CaseStudies: React.FC<PageProps<DataProps>> = ({ data }) => {
  useEffect(() => {
    navigate("/case-studies/" + data.allCaseStudy.nodes[0].slug);
  }, []);

  return null;
};

export const query = graphql`
  query {
    allCaseStudy(limit: 1) {
      nodes {
        slug
      }
    }
  }
`;

export default CaseStudies;
